<template>
  <section form-register v-editable="blok">
    <div class="container container--bleed">
      <div class="row">
        <div class="col">
          <div class="section-header">
            <h1 class="section-title" v-html="title"></h1>
            <div class="section-description" v-html="description"></div>
          </div>
          <div class="section-form">
            <FormsRegister :blok="blok"/>
          </div>
        </div>
        <div class="col section-sidebar-col" v-if="!blok.hide_developer_box">
          <aside class="section-sidebar">
            <h2>Are you a <br /><b>Developer?</b></h2>
            <div class="section-buttons">
              <a href="https://developers.kudosity.com" target="_blank" class="btn btn-primary">Access Developer Portal</a>
              <a href="/developer-trial" class="btn btn-outline-dark">Start Developer Trial</a>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/form-register.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });

const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));
</script>
